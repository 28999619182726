import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Button } from 'reactstrap';
import { MdPersonOutline, MdExitToApp } from 'react-icons/md';
import Logo from '../static/img/logo-jmb.png';
import api from '../api';
import { getUserProfile } from '../store/reducers/user';
import { logoff } from '../store/actions/authActions';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      user: {},
    };
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser = async () => {
    try {
      const user = await api.auth.me();
      this.setState({ loading: false, user });
    } catch (err) {
      this.setState({ loading: false, loadError: err.message });
    }
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  logoff = () => {
    api.auth.logout().then(() => {
      this.props.logoff();
    });
  };

  render() {
    return (
      <>
        <Navbar color="dark" dark expand="md">
          <NavbarBrand>
            <Link to="/">
              <img src={Logo} alt="Logotipo" style={{ width: '100px', height: '43px', border: '0' }} />
            </Link>
          </NavbarBrand>
          <NavbarBrand>
            <h3 style={{ color: '#00a859', marginTop: '15px', marginLeft: '5px' }}>
              {process.env.REACT_APP_IS_JMB === 'S'
                ? process.env.REACT_APP_EMPRESA_JMB
                : process.env.REACT_APP_EMPRESA_JBB}
            </h3>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="https://www.alpha.com.vc" style={{ marginTop: '7px', color: '#00a859' }}>
                  <small>1.5.6</small>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink style={{ marginTop: '7px', marginRight: '20px', color: '#00a859' }}>
                  <small>Bem-vindo: {this.state.user.nome} </small>
                </NavLink>
              </NavItem>
              <NavItem>
                <Link to="/perfil" style={{ textDecoration: 'none' }}>
                  <Button type="button" block style={{ marginTop: '8px' }}>
                    <MdPersonOutline style={{ marginRight: '5' }} />
                    Minha Conta
                  </Button>
                </Link>
              </NavItem>
              <NavItem>
                <NavLink>
                  <Button type="button" block onClick={this.logoff}>
                    <MdExitToApp style={{ marginRight: '5' }} />
                    Sair
                  </Button>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        <br />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: getUserProfile(state),
  };
};

Navigation.propTypes = {
  logoff: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { logoff })(Navigation);
